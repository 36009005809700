import React from 'react';
import Header from './Header';
import Footer from './Footer';
import Payoff from "./Payoff";

type Props = {
    children?: React.ReactNode;
    payoffDisplay?: boolean;
};

const Layout = ({ children, payoffDisplay = false }: Props) => {
    return (
        <div className="flex flex-col min-h-screen">
            <Header />
            {payoffDisplay &&  <Payoff />}
            <div className="flex-1 flex flex-col items-center">{children}</div>
            <Footer />
        </div>
    );
};

export default Layout;
