import React from 'react';
import {Link, useNavigate} from "react-router-dom";

type Props = {
    to: string,
    text: string,
    className?: string
}

const BackLink = ({to, text, className = ""}: Props) => {

    const navigate = useNavigate();

    const goTo = () => {
        navigate(to);
    }

    return (
        <Link to={to} onClick={goTo} className={`cursor-pointer hover:text-blue-600 ${className}`}>
            {text}
        </Link>
    );
};

export default BackLink;
