import { ArticlesActionType } from '../reducer-types';

export const defaultState = {
    articles: [],
    categories: [],
    filter: null,
};

function ArticlesReducer(state: any, action: any) {
    switch (action.type) {
        case ArticlesActionType.LOAD_ARTICLES:
            return {
                ...state,
                articles: action.articles,
            };
        case ArticlesActionType.LOAD_CATEGORIES:
            return {
                ...state,
                categories: action.categories,
            };
        case ArticlesActionType.SET_FILTER:
            return {
                ...state,
                filter: action.filter,
            }
        default:
            throw new Error('Unhandled action type: ' + action.type);
    }
}

export default ArticlesReducer;
