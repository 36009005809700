export const list = {
    visible: {
        opacity: 1,
        transition: {
            when: 'beforeChildren',
            staggerChildren: 0.02,
            duration: 0.2,
        }
    },
    hidden: {
        opacity: 0,
        transition: {
            when: 'beforeChildren'
        }
    },
    exit: {
        transition: {
            when: 'beforeChildren',
            staggerChildren: 0.2
        }
    },
    hover: {},
}

export const item = {
    visible: {opacity: 1, scale: 1},
    hidden: {opacity: 0, scale: 0.5},
}

export const colorItem = {
    visible: {opacity: 1, scale: 1},
    hidden: {opacity: 0, scale: 0.5},
}

export const cartItem = {
    visible: {opacity: 1, x: 0},
    hidden: {opacity: 0, x: -200}
}

export const colorList = {
    visible: {
        opacity: 1,
        transition: {
            when: 'beforeChildren',
            staggerChildren: 0.2
        }
    },
    hidden: {
        opacity: 0,
    }
}

