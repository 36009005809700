import React from 'react';
import {useCart} from "../../utils/cart-context/cart-context";
import {IndexedItem} from "../../types";
import {cartHasItem, getCartItem} from "../../utils/cart-utility";
import {BsCartCheckFill} from "react-icons/bs";
import {motion} from 'framer-motion';
import {colorItem} from "../animations/animation";
import ColorCartDetails from "./ColorCartDetails";
import AddedToCartIcon from "../ui/AddedToCartIcon";

type Props = {
    color: {
        id: number,
        name: string,
        image_url: string,
    },
    onClick: (color: IndexedItem) => void
}

const Color = ({color, onClick}: Props) => {

    const {cart, currentArticle} = useCart();

    const container_style = {
        backgroundImage: `url("${color.image_url}")`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    }

    return (
        <motion.div variants={colorItem}>
            <motion.div className="shadow cursor-pointer"
                        onClick={() => onClick(color)}
                        whileHover={{scale: 1.01}}
                        whileTap={{scale: 0.99}}>
                <motion.div style={container_style}
                            className="h-24 bg-gray-400"
                            whileHover={{backgroundColor: '#00000088'}}/>
                <motion.div className="flex flex-row items-end p-2 h-full w-full">
                    <div className="flex flex-row items-center justify-between w-full">
                        <div className="text-black font-medium">{color.name ? color.name : "color name"}</div>
                        {currentArticle && cartHasItem(cart, currentArticle, color) && <AddedToCartIcon />}
                    </div>
                </motion.div>
                {currentArticle && <ColorCartDetails color={color} />}
            </motion.div>
        </motion.div>

    );
};

export default Color;
