import React from 'react';
import {BsCartCheckFill} from "react-icons/bs";

const AddedToCartIcon = () => {
    return (
        <BsCartCheckFill className="text-green-500" title="Aggiunto alla richiesta"/>
    );
};

export default AddedToCartIcon;
