import {ArticlesActionType} from "../reducer-types";

function loadArticles(articles: []) {
    return {
        type: ArticlesActionType.LOAD_ARTICLES,
        articles,
    };
}

function loadCategories(categories: []) {
    return {
        type: ArticlesActionType.LOAD_CATEGORIES,
        categories,
    };
}

function setFilter(filter: number | null) {
    return {
        type: ArticlesActionType.SET_FILTER,
        filter,
    }
}

const actions = {
    loadArticles,
    loadCategories,
    setFilter
}

export default actions;

