import React from 'react';

type Props = {
    maxWidth?: string,
    children: React.ReactNode,
    className?: string,
}

const MaxWidthDiv = ({maxWidth= '1024px', children, className = ""}: Props) => {
    return (
        <div style={{maxWidth: maxWidth, width: '100%', margin: '0 auto'}} className={className}>
            {children}
        </div>
    );
};

export default MaxWidthDiv;
