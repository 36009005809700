import React, {useState} from 'react';
import {CartCategory} from "../../types";
import BackGroundImage from "../ui/BackGroundImage";
import {FaChevronDown, FaChevronUp} from "react-icons/fa";
import ArticleCartItem from "../Article/ArticleCartItem";
import {motion} from 'framer-motion';
import {list} from "../animations/animation";

type Props = {
    category: CartCategory
}

const CategoryContainer = ({category}: Props) => {

    const [categoryOpen, setCategoryOpen] = useState(false);

    const countItems = () => {
        const sum = category.items.map(item => item.amount)
            .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
        return sum;

    }

    return (
        <div>
            <div className="flex flex-row items-center justify-between shadow px-4 py-2 rounded">
                <BackGroundImage image_url={category.article.image_url} className="w-32 md:w-64 h-24 bg-gray-400 rounded"/>
                <div className="h-full font-medium flex-1 ml-4">
                    <div className="text-eBLUE text-2xl">{category.article.name}</div>
                    <div className="flex flex-col sm:flex-row text-sm sm:text-base">
                        <p className="flex-1">{category.article.description}</p>
                        <div className="mt-2 sm:mt-0 ml-2 flex-1 text-right mr-4">{countItems()} pz</div>
                    </div>
                </div>

                <button type="button"
                        className="cursor-pointer hover:text-blue-600 text-2xl"
                        onClick={() => setCategoryOpen(categoryOpen => !categoryOpen)}>
                    {categoryOpen
                        ? <FaChevronUp/>
                        : <FaChevronDown/>
                    }
                </button>

            </div>
            {categoryOpen &&
            <motion.div className="p-4 bg-gray-100" variants={list} initial="hidden" animate="visible">
                {category.items && category.items.length > 0 &&
                category.items.map((item, i) => <ArticleCartItem item={item} key={i}/>)
                }
            </motion.div>
            }
        </div>
    );
};

export default CategoryContainer;
