import React from 'react';

type Props = {
    image_url?: string | undefined,
    className?: string,
    children?: React.ReactNode,
}

const BackGroundImage = ({image_url, children, className = ""}: Props) => {

    const container_style = {
        backgroundImage: `url("${image_url ? image_url : ""}")`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    }

    return (
        <div style={container_style} className={className}>
            {children}
        </div>
    );
};

export default BackGroundImage;