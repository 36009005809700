import React from 'react';
import {getCartItem} from "../../utils/cart-utility";
import {useCart} from "../../utils/cart-context/cart-context";
import {IndexedItem} from "../../types";
import ItemAmountCounter from "./ItemAmountCounter";

type Props = {
    color: {
        id: number,
        name: string,
        image_url: string,
    }
}

const ColorCartDetails = ({color}: Props) => {

    const {cart, currentArticle} = useCart();

    const handleClick = (e: any) => {
         e.stopPropagation();
    }
    if(currentArticle) {
        const item = getCartItem(cart, currentArticle, color);
        if(item && item != undefined) {
            return (
                <div className="p-1 flex flex-row justify-between" onClick={handleClick}>
                    <div className="text-sm">Aggiunti al carrello: </div>
                    <ItemAmountCounter item={item}/>
                </div>
            );
        }
    }
    return null;
};

export default ColorCartDetails;
