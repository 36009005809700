import React, {useEffect} from 'react';
import Layout from '../components/ui/website_structure/Layout';
import {doGetArticles} from "../logics/articles";
import useApi from "../utils/custom_hooks/api-hook";
import {useArticles} from "../utils/articles-context/articles-context";
import Categories from "../components/Articles/Categories";
import ArticleList from "../components/Articles/ArticleList";
import MaxWidthDiv from "../components/ui/website_structure/MaxWidthDiv";

const Home = () => {

    const {call, loading} = useApi();
    const {loadArticles, loadCategories} = useArticles();

    useEffect(() => {
        const load = async () => {
            await call(
                () => doGetArticles(),
                (response) => {
                    if(response.articles) loadArticles(response.articles);
                    if(response.categories) loadCategories(response.categories);
                }
            )
        }

        load();
    }, []);

    return (
        <Layout payoffDisplay={true}>
            {loading ?
                <div>Caricamento</div>
                :
                <MaxWidthDiv>
                    <Categories />
                    <ArticleList />
                </MaxWidthDiv>
            }
        </Layout>
    );
};

export default Home;
