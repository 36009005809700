import React from 'react';
import {useCart} from "../../utils/cart-context/cart-context";

const OrderRecap = () => {

    const {restructureCart} = useCart();

    return (
        <div className="flex-1 h-full shadow-lg rounded p-4 ">
            <div className="font-bold text-xl">Articoli aggiunti</div>
            <div>
                {restructureCart().map((category, cat_index) =>
                    <div key={cat_index}>
                        <div className="font-medium text-lg">{category.article.name}</div>
                        <div>
                            {category.items.map((item, item_index) =>
                                <div key={`item_${cat_index}_${item_index}`}>
                                    <div className="flex flex-row items-center gap-2 text-sm">
                                        <div>{item.article.name}</div>
                                        <div>{item.color.name}</div>
                                        <div className="rounded-full text-sm font-medium bg-gray-200 w-4 h-4 flex flex-row items-center justify-center">{item.amount}</div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {!(restructureCart().length > 0) &&
                    <div className="text-gray-400">Nessun elemento presente</div>
                }
            </div>
        </div>
    );
};

export default OrderRecap;
