import {CartItem, IndexedItem} from "../types";

function cartHasItem (cart: Array<CartItem>, article: IndexedItem, color: IndexedItem) {
    return cart.some((item: CartItem) => isSameCartItem(item, article, color));
}

function isSameCartItem (item: CartItem, article: IndexedItem, color: IndexedItem) {
    return item.article.id == article.id && color.id == item.color.id;
}

function getCartItem(cart: Array<CartItem>, article: IndexedItem, color: IndexedItem) {
    return cart.find((item: CartItem) => isSameCartItem(item, article, color));
}

function cartHasArticle(cart: Array<CartItem>, article: IndexedItem) {
    return cart.some((item: CartItem) => item.article.id === article.id);
}

export {
    cartHasItem,
    isSameCartItem,
    getCartItem,
    cartHasArticle
}
