import React from 'react';

type Props = {
    className?: string,
    children?: React.ReactNode,
}

const Row = ({children, className = ""}: Props) => {
    return (
        <div className={`flex flex-row ${className}`}>
            {children}
        </div>
    );
};

export default Row;