import React from 'react';
import MaxWidthDiv from "../ui/website_structure/MaxWidthDiv";
import {useCart} from "../../utils/cart-context/cart-context";

const ArticleDetails = () => {

    const {currentArticle} = useCart();

    const container_style = {
        backgroundImage: `url("${currentArticle ? currentArticle.image_url : ""}")`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '400px'
    }

    return (
        <div className="flex flex-row justify-start w-full shadow-md">
            <MaxWidthDiv className="flex flex-row gap-2">
                <div className="h-32 w-86 flex items-end p-2 rounded bg-gray-400" style={container_style}>
                    <div className="text-lg font-bold text-white">{currentArticle && currentArticle.name}</div>
                </div>
                <div className="p-4 text-eBLUE font-bold">{currentArticle && currentArticle.description}</div>
            </MaxWidthDiv>
        </div>
    );
};

export default ArticleDetails;
