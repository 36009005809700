import React from 'react';

const Footer = () => {
    return (
        <div className="p-2 bg-eBLUE">
            <p className="text-xs text-white text-right">
                Powered by{' '}
                <a
                    href="https://www.net-evolution.com/"
                    target="_blank"
                    className="font-medium cursor-pointer hover:underline"
                    rel="noreferrer"
                >
                    Netevolution
                </a>
            </p>
        </div>
    );
};

export default Footer;
