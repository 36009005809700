import {API} from "./api_config";

const COMPANY = process.env.REACT_APP_COMPANY;

async function doGetArticles() {
    return API.get(`/v1/expressleather/companies/${COMPANY}/articles/`);
}

async function doGetArticle(id: string | undefined) {
    return API.get(`/v1/expressleather/companies/${COMPANY}/articles/${id}`);
}

export {
    doGetArticles,
    doGetArticle
};
