import React from 'react';
import {ArticlesProvider} from "../../utils/articles-context/articles-context";
import {CartProvider} from "../../utils/cart-context/cart-context";

type Props = {
    children: React.ReactNode,
}

const MainProvider = ({children}: Props) => {
    return (
        <ArticlesProvider>
            <CartProvider>
                {children}
            </CartProvider>
        </ArticlesProvider>
    );
};

export default MainProvider;
