import React from 'react';
import MaxWidthDiv from "./MaxWidthDiv";
import {useNavigate} from "react-router-dom";
import {IoCartOutline} from "react-icons/io5";
import {useCart} from "../../../utils/cart-context/cart-context";

const Header = () => {

    const {cart} = useCart();

    const navigate = useNavigate();

    const goBackToHome = () => {
        navigate('/');
    }

    const goToCart = () => {
        navigate('/cart');
    }

    return (
        <header className="w-full">
            <div className="bg-eBLUE p-2">
                <MaxWidthDiv className="flex flex-row justify-between items-center">
                    <h1 className="text-white font-bold text-lg cursor-pointer" onClick={goBackToHome}>Express Leathers</h1>

                    <div className="relative">
                        <IoCartOutline className="text-white text-xl cursor-pointer" onClick={goToCart}/>
                        {cart && cart.length > 0 &&
                            <div className="absolute top-0 right-0 bg-red-500 rounded-full" style={{width: "5px", height: "5px"}}/>
                        }
                    </div>
                </MaxWidthDiv>
            </div>

        </header>
    );
};

export default Header;
