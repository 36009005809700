import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import {useCart} from "../utils/cart-context/cart-context";
import useApi from "../utils/custom_hooks/api-hook";
import {doGetArticle} from "../logics/articles";
import Layout from "../components/ui/website_structure/Layout";
import Loading from "../components/ui/Loading";
import ArticleDetails from "../components/Article/ArticleDetails";
import Colors from "../components/Article/Colors";
import ArticleCart from "../components/Article/ArticleCart";
import BackLink from "../components/ui/BackLink";
import MaxWidthDiv from "../components/ui/website_structure/MaxWidthDiv";

const Article = () => {

    const {id} = useParams();
    const {cart, currentArticle, setCurrentArticle} = useCart();
    const {call, loading} = useApi();

    const filterByCurrentArticle = () => {
        if(currentArticle) {
            return cart.filter(item => item.article.id === currentArticle.id);
        } else {
            return [];
        }
    }

    useEffect(() => {
        const load = async () => {
            await call(
                () => doGetArticle(id),
                (res) => {
                    if(res.articles && res.articles.length > 0) {
                        setCurrentArticle(res.articles[0]);
                    }
                }
            )
        }

        load();
    }, [])

    return (
        <Layout>
            <MaxWidthDiv className="mb-4">
                <BackLink text={'Torna alla scelta degli articoli'}
                          className='my-2'
                          to={'/'}/>
            </MaxWidthDiv>
            {loading ?
                <Loading/>
                :
                currentArticle &&
                <div className="w-full">
                    <ArticleDetails />
                    <MaxWidthDiv className="flex flex-row">
                        {currentArticle.colors && <Colors />}

                    </MaxWidthDiv>
                </div>
            }
        </Layout>
    );
};

export default Article;
