import React from 'react';
import Layout from "../components/ui/website_structure/Layout";
import OrderContainer from "../components/Order/OrderContainer";

const Order = () => {
    return (
        <Layout>
            <OrderContainer />
        </Layout>
    );
};

export default Order;
