import React from 'react';
import LabelledInput from "../ui/LabelledInput";
import Row from "../ui/Row";
import LabelledTextArea from "../ui/LabelledTextArea";
import useApi from "../../utils/custom_hooks/api-hook";
import {doPostOrder} from "../../logics/orders";

const Form = () => {

    const {call} = useApi();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const email = e.currentTarget.email.value;
        const phone = e.currentTarget.phone.value;
        const firstname = e.currentTarget.firstname.value;
        const lastname = e.currentTarget.lastname.value;
        const company = e.currentTarget.company.value;
        const note = e.currentTarget.note.value;

        const payload = {
            email,
            phone,
            firstname,
            lastname,
            company,
            note
        }

        await call(() => doPostOrder(payload),
            (res) => {
                console.log('success');
            },
            () => {
                console.log('failure')
            }
        );

    }

    return (
        <div className="flex-1 h-full">
            <div className="text-2xl text-eBLUE text-center font-bold">Richiedi il preventivo</div>
            <form className="flex flex-col gap-2" onSubmit={handleSubmit}>
                <Row className="gap-2 w-full">
                    <LabelledInput label="email" name="email" required={true}/>
                    <LabelledInput label="telefono" name="phone" required={true}/>
                </Row>
                <Row className="gap-2 w-full">
                    <LabelledInput label="Nome" name="firstname" required={true}/>
                    <LabelledInput label="Cognome" name="lastname" required={true}/>
                </Row>
                <Row className="gap-2 w-full">
                    <LabelledInput label="Azienda" name="company" required={true}/>
                </Row>
                <LabelledTextArea label='Note' name='note' placeholder='Note'/>

                <button type="submit"
                        className="bg-eBLUE hover:bg-eBLUE300 active:bg-gray-500 text-white p-1 rounded mt-4">Effettua
                    richiesta
                </button>
            </form>
        </div>
    );
};

export default Form;
