import React from 'react';
import {CartItem} from "../../types";
import {FaMinus, FaPlus, FaTrashAlt} from "react-icons/fa";
import {useCart} from "../../utils/cart-context/cart-context";
import ItemAmountCounter from "./ItemAmountCounter";
import {motion} from 'framer-motion';
import {cartItem} from "../animations/animation";

type Props = {
    item: CartItem
}

const ArticleCartItem = ({item}: Props) => {

    const {removeFromCart, addToCart} = useCart();

    const handleDelete = () => {
        removeFromCart(item.article, item.color);
    }

    return (
        <motion.div className="flex flex-row items-center gap-2 p-2 text-eBLUE"
                    variants={cartItem}>
            <div className="font-bold flex-1">{item.article.name} - {item.color.name}</div>
            <ItemAmountCounter item={item} className="mr-8"/>
            <FaTrashAlt onClick={handleDelete}
                        title="Rimuovi"
                        size={15}
                        className="cursor-pointer hover:text-red-500"/>
        </motion.div>
    );
};

export default ArticleCartItem;
