import React from 'react';

const Payoff = () => {
    return (
        <div className="bg-blue-200 text-center p-4">
            <div className="text-white text-xl my-4">Payoff</div>
            <div className="text-white font-medium">LOGO</div>
        </div>
    );
};

export default Payoff;