enum ArticlesActionType {
    LOAD_ARTICLES = 'LOAD_ARTICLES',
    LOAD_CATEGORIES = 'LOAD_CATEGORIES',
    SET_FILTER = 'SET_FILTER'
}

enum CartActionType {
    ADD_TO_CART = 'ADD_TO_CART',
    REMOVE_FROM_CART = 'REMOVE_FROM_CART',
    LOAD_CART = 'LOAD_CART',
    SET_CURRENT_ARTICLE = 'SET_CURRENT_ARTICLE',
    CHANGE_ITEM_AMOUNT = 'CHANGE_ITEM_AMOUNT'
}

export {
    ArticlesActionType,
    CartActionType
};
