import React from 'react';
import MaxWidthDiv from "../ui/website_structure/MaxWidthDiv";
import {useCart} from "../../utils/cart-context/cart-context";
import {CartCategory as Category} from "../../types";
import CategoryContainer from "./CategoryContainer";
import {useNavigate} from "react-router-dom";
import BackLink from "../ui/BackLink";

const CartArticleList = () => {

    const {cart, restructureCart} = useCart();
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/order');
    }

    return (
        <MaxWidthDiv className="py-2">
            <BackLink text={'Torna alla scelta degli articoli'}
                      to={'/'}/>
            <div className="flex flex-row items-center justify-between mb-4">
                <div className="text-2xl ">Articoli aggiunti</div>
                <button className="bg-eBLUE rounded font-bold text-white p-1 px-2" onClick={handleClick}>Procedi con la richiesta</button>
            </div>
            {restructureCart().map((category: Category, i: number) =>
                <CategoryContainer category={category} key={i}/>
            )}
            {!cart || cart.length == 0 &&
                <div>Nessun articolo presente nel carrello</div>
            }
        </MaxWidthDiv>
    );
};

export default CartArticleList;
