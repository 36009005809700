import {API} from "./api_config";

const COMPANY = process.env.REACT_APP_COMPANY;

function doPostOrder(payload:any) {
    return API.post(`/v1/expressleather/companies/${COMPANY}/order`, payload);
}

export {
    doPostOrder,
}
