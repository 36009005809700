import {useState} from "react";

function useApi() {

    const [loading, setLoading] = useState(false);

    async function call(func: () => any, onSuccess: (r?: any) => any, onFailure?: (r?: any) => any) {
        setLoading(true);
        const _response = await func();
        if(_response.data) {
            const response = _response.data;
            if (response.status === "ok") {
                if(response.data) {
                    onSuccess(response.data);
                }
            } else {
                onFailure && onFailure(response.error);
            }
            setLoading(false);
        }

    }

    return {
        call,
        loading,
    }
}

export default useApi;
