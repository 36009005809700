import React from 'react';
import AppNavigation from './components/app/AppNavigation';
import MainProvider from "./components/app/MainProvider";

function App() {
    return (
        <MainProvider>
            <AppNavigation/>
        </MainProvider>
    );
}

export default App;
