import React from 'react';
import Form from "./Form";
import OrderRecap from "./OrderRecap";
import MaxWidthDiv from "../ui/website_structure/MaxWidthDiv";
import BackLink from "../ui/BackLink";

const OrderContainer = () => {
    return (
        <MaxWidthDiv className="p-4 ">
            <BackLink to="/" text="Torna agli articoli" />
            <div className="flex flex-col-reverse md:flex-row md:items-start gap-4">
                <Form />
                <OrderRecap />
            </div>
        </MaxWidthDiv>
    );
};

export default OrderContainer;
