import React from 'react';
import {useCart} from "../../utils/cart-context/cart-context";
import {IndexedItem} from "../../types";
import Color from "./Color";
import {motion} from 'framer-motion';
import {list} from "../animations/animation";

const Colors = () => {

    const {currentArticle, addToCart} = useCart();

    const handleClick = (color: IndexedItem) => {
        currentArticle && addToCart(currentArticle, color);
    }

    return (
        <div className="flex-1">
            <div className="w-full italic text-gray-600 font-bold text-sm pt-2">
            {!currentArticle?.colors || currentArticle.colors.length == 0 ?
                <div className="text-center">Nessun colore presente per questo articolo</div>
                :
                <div className="">Clicca su un colore per aggiungerlo al carrello</div>
            }
            </div>
            <motion.div variants={list}
                        initial="hidden"
                        animate="visible"
                        className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 py-4 px-2">
                {currentArticle?.colors && currentArticle.colors.length > 0 && currentArticle.colors.map((color, i) =>
                    <Color key={i}
                           color={color}
                           onClick={handleClick}/>
                )}
            </motion.div>
        </div>
    );
};

export default Colors;
